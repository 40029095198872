import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { AlertComponent } from '../shared/alert/alert.component';
import { DialogComponent } from '../shared/dialog/dialog.component';
import { Location } from '@angular/common';
import { CustomValidators } from './custom-validators';
// // -----------------------------------------------------------------------------
// //  Services
// // -----------------------------------------------------------------------------
import { SecurityService, UserService } from '../services/services.index';
// -----------------------------------------------------------------------------
//  Models
// -----------------------------------------------------------------------------
import { User } from '../models/user.model';
import { environment } from 'src/environments/environment.js';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  environmentName = environment.name;

  singin = true;
  hide = true;
  formSignIn: FormGroup;
  formSignUp: FormGroup;
  formRecover: FormGroup;
  formSetPassword: FormGroup;

  patt = '!@#$%^&*()_+-={};:\|,.<>?';
  action = 'hide';
  token = '';
// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
// authenticated = false;
  constructor( private securityService: SecurityService,
               private router: Router,
               private userService: UserService,
               private activatedRoute: ActivatedRoute,
               private location: Location,
               private snackBar: MatSnackBar,
               private dialog: MatDialog) {



                this.activatedRoute.params.subscribe( params => {

                  if ( params.action === 'resetpassword' ) {
                    this.action = 'resetPassword';
                    this.token = params.id;
                  }  else {
                    this.action = 'hide';
                  }
                });

       // this.authenticated = this.securityService.isAuthenticated();


  }
// -----------------------------------------------------------------------------
//  Object init
// -----------------------------------------------------------------------------
  ngOnInit() {


    this.showPopup();

    const email = localStorage.getItem('email') || '';
    const rememberme = email.length > 0 ? true : false;




    this.formSignIn = new FormGroup({
      email           : new FormControl('', [Validators.required, Validators.email]),
      password        : new FormControl(null, Validators.required),
      rememberme      : new FormControl(rememberme)
    });

    this.formSignUp = new FormGroup({
      password        : new FormControl(null,

        Validators.compose([
          Validators.required,
          // check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, {
            hasNumber: true
          }),
          // check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, {
            hasCapitalCase: true
          }),
          // check whether the entered password has a lower case letter
          CustomValidators.patternValidator(/[a-z]/, {
            hasSmallCase: true
          }),
          // check whether the entered password has a special character
          CustomValidators.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
            hasSpecialCharacters: true
          }),
          Validators.minLength(8)
        ]),

        ),
        confirmPassword : new FormControl(null, [Validators.required]),
        email           : new FormControl('', [Validators.required, Validators.email])
    }, {
      validators: this.passwordConfirm('password', 'confirmPassword')
    });

    this.formRecover = new FormGroup({
      email           : new FormControl('', [Validators.required, Validators.email])
    });

    this.formSetPassword = new FormGroup({
      password        : new FormControl(null, Validators.required),
      password2       : new FormControl(null, [Validators.required]),
    }, {
      validators: this.passwordConfirm('password', 'password2')
    });

  }
// -----------------------------------------------------------------------------
//  Validate writing correctly password
// -----------------------------------------------------------------------------
passwordConfirm( pass1: string, pass2: string ) {
  return ( group: FormGroup ) => {
    const value1 = group.controls[pass1].value;
    const value2 = group.controls[pass2].value;

    if ( value1 === value2) {
      return null;
    }

    return {
      passwordConfirm: true
    };
  };
}
// -----------------------------------------------------------------------------
//  Autenticate user
// -----------------------------------------------------------------------------
  signIn() {

    this.validateAllFormFields( this.formSignIn );
    if (!this.formSignIn.valid) {
      return;
    }

    this.securityService.signIn(
    this.formSignIn.value.email,
    this.formSignIn.value.password,
    this.formSignIn.value.rememberme ).subscribe( businessResponse => {


      if ( businessResponse ) {


        this.snackBar.open('Bienvenido a ProSostenible', null, {
          duration: 5000, horizontalPosition: 'left'
        });
        this.router.navigate(['/private/myrequests']);


      } else {
        this.snackBar.open('Acceso denegado', null, {
          duration: 5000, horizontalPosition: 'left'
        });
      }





    }, error => {

      switch ( error.status ) {
        case 406:
        this.snackBar.open('Usuario y/o contraseña incorrecta', null, {
          duration: 5000, horizontalPosition: 'left'
        });
        break;
        default:
        this.snackBar.open('Servicio no diponible, intenta más tarde', null, {
          duration: 5000, horizontalPosition: 'left'
        });
        break;
      }
    });
  }
// -----------------------------------------------------------------------------
//  sign Up
// -----------------------------------------------------------------------------
  signUp() {
    this.validateAllFormFields( this.formSignUp );

    if ( this.formSignUp.value.password !== this.formSignUp.value.confirmPassword ) {
      this.snackBar.open('Las contraseñas no coinciden', null, {
        duration: 5000, horizontalPosition: 'left'
      });
      return;
    }

    if (!this.formSignUp.valid) {
      this.snackBar.open('Contraseña invalida', null, {
        duration: 5000, horizontalPosition: 'left'
      });
      return;
    } else {
    }

    const user = new User( '', '', '',
      this.formSignUp.value.email
    );

    this.userService.createCustomer( user, this.formSignUp.value.password ).subscribe( headerResponse => {
      this.showMenu();
      const dialogRef = this.dialog.open(AlertComponent, {
        data: {
          message: 'Registro realizado correctamente.',
          buttonText: {
            cancel: 'Inicia sesión'
          }
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        this.action = 'singin';
        this.formRecover.reset();
        this.formSignUp.reset();
        this.formSignIn.reset();
        this.formSetPassword.reset();
      });

  }, ( error ) => {

    switch ( error.status ) {
      case 409:
      this.snackBar.open('El correo electrónico ya esta registrado', null, {
        duration: 5000, horizontalPosition: 'left'
      });
      break;
      default:
      this.snackBar.open('Servicio no diponible, intenta más tarde', null, {
        duration: 5000, horizontalPosition: 'left'
      });
      break;
    }

  });
}
// -----------------------------------------------------------------------------
//  Form validation
// -----------------------------------------------------------------------------
  validateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });

  }

// -----------------------------------------------------------------------------
//  recover
// -----------------------------------------------------------------------------
recover() {
  this.validateAllFormFields( this.formRecover );
  if (!this.formRecover.valid) {
    return;
  }

  const user = new User( '', '', '',
    this.formRecover.value.email
  );

  this.securityService.recoverPassword( user ).subscribe( headerResponse => {
    this.showMenu();
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {
        message: `Se envió un correo electrónico a ${ this.formRecover.value.email } para realizar la recuperación de tu contraseña.`,
        buttonText: {
          cancel: 'Enterado'
        }
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.formRecover.reset();
      this.formSignUp.reset();
      this.formSignIn.reset();
      this.formSetPassword.reset();
      this.action = 'hide';
    });

  }, ( error ) => {

    switch ( error ) {
      case 406:
      this.snackBar.open('El correo electrónico no esta registrado', null, {
        duration: 5000, horizontalPosition: 'left'
      });
      break;
      default:
      this.snackBar.open('Servicio no diponible, intenta más tarde', null, {
        duration: 5000, horizontalPosition: 'left'
      });
      break;
    }

  });
}

changePassword() {
  this.validateAllFormFields( this.formSetPassword );
  if ( this.formSetPassword.value.password !== this.formSetPassword.value.password2 ) {
    this.snackBar.open('Las contraseñas no coinciden', null, {
      duration: 5000, horizontalPosition: 'left'
    });
    return;
  }

  if (!this.formSetPassword.valid) {
    return;
  } else {
  }

  const user = new User( '', '', '',
    this.formSetPassword.value.email
  );

  this.securityService.changePassword( this.token, this.formSetPassword.value.password ).subscribe( headerResponse => {
    this.showMenu();
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {
        message: 'Cambio de contraseña realizada correctamente',
        buttonText: {
          cancel: 'Cerrar'
        }
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.formRecover.reset();
      this.formSignUp.reset();
      this.formSignIn.reset();
      this.formSetPassword.reset();
      this.router.navigate(['/']);

      this.action = 'singin';
   // this.location.replaceState('/');
    });

}, ( error ) => {

  switch ( error ) {
    case 406:

        const dialogRef = this.dialog.open(AlertComponent, {
          data: {
            message: 'El token de activación es invalido o ha caducado, solicita la recuperación de tu contraseña nuevamente',
            buttonText: {
              cancel: 'Solicitar'
            }
          },
        });
        dialogRef.afterClosed().subscribe(result => {
          this.formSetPassword.reset();
          this.action = 'recover';
          this.location.replaceState('/signin');
        });

        break;
    default:
    this.snackBar.open('Servicio no diponible, intenta más tarde', null, {
      duration: 5000,
    });
    break;
  }

});
}

showMenu() {
  if ( this.action === 'hide' ) {
    this.action = 'singin';
  } else {
    this.action = 'hide';
  }
}



showPopup() {

  const dialogRef = this.dialog.open( AlertComponent, {
    data: {
      message:
      `<p>
      La Consultoría integrada por Carbon Trust México, CIRAD
y El Buen Socio es la entidad responsable para efectuar el análisis de elegibilidad técnica al Programa de Apoyo a Proyectos Sostenibles,
y únicamente a partir de la información proporcionada por el solicitante a través de esta plataforma, se emite la Constancia
de Elegibilidad Técnica al Programa, documento que se requiere presentar al Intermediario Financiero de su preferencia, que tenga relación
directa con FIRA, para realizar el procedimiento crediticio correspondiente.
</p>
      `,
      buttonText: {
        cancel: 'Enterado',
      }
    },
  });
  dialogRef.afterClosed().subscribe(result => {

    if (result) {

    }
  });

}




// -----------------------------------------------------------------------------
//  Terms
// -----------------------------------------------------------------------------
goTerms() {
  // window.open(`https://www.elbuensocio.com.mx/aviso-de-privacidad.html`, '_blank');
  window.open(`https://prosostenible.org/privacy_policy`, '_blank');
}

}





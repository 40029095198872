import { Component, OnInit } from '@angular/core';
import { CatalogService } from '../../../services/services.index';
import { environment } from 'src/environments/environment.js';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./events-detail.component.scss']
})
export class EventDetailComponent implements OnInit {
  existFile = false;

  searchigFiles = true;
  fileList = [];

  title = '';

  idEvent = '';

  awsS3Url = environment.endpoint.awsS3;

  constructor(
    private catalogService: CatalogService,
    private activatedRoute: ActivatedRoute
    ) {

    this.activatedRoute.params.subscribe( params => {


      this.idEvent = params.id;

    });
  }

  ngOnInit() {



    this.searchigFiles = true;
    this.catalogService.getEvent( this.idEvent ).subscribe( resp => {


      // console.log(resp);

      resp.forEach( item => {


        console.log(item);

        const c = item.Key.split('/');
        const ext = c[2].split('.');

        this.title = c[1].replace(/_/g, ' ').split('|')[1];


        if ( ext [0] !== 'cover') {
          this.fileList.push({
            path: item.Key,
            file: ext [0].replace(/_/g, ' '),
            ext: ext [ ext.length - 1 ]
          } );

        }

      });


      this.searchigFiles = false;

    });
  }


  showDocument(urlResource) {
    window.open(urlResource, '_blank');
  }



}

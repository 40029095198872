import { Component, OnInit } from '@angular/core';
import { CatalogService } from '../../services/services.index';
import { environment } from 'src/environments/environment.js';
import { Router } from '@angular/router';
import { SecurityService } from 'src/app/services/services.index';


@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  existFile = false;

  searchigFiles = true;
  fileList = [];



  awsS3Url = environment.endpoint.awsS3;

  constructor( private catalogService: CatalogService,
               private securityService: SecurityService,
               private router: Router,
    ) { }

  ngOnInit() {



    this.searchigFiles = true;
    this.catalogService.getEvents().subscribe( resp => {


      console.log(resp);

      // resp.CommonPrefixes.shift();

      resp.CommonPrefixes.forEach( item => {

        const c = item.Prefix.split('/');
        const ext = c[1].split('|');

        this.fileList.push({
          indice: ext [0],
          title: ext [1].replace(/_/g, ' '),
          date: ext [2].replace(/_/g, ' '),
          path: item.Prefix

          // ext: ext [ ext.length - 1 ]
         } );

      });

      this.searchigFiles = false;

    });
  }


  showDocument(urlResource) {
    window.open(urlResource, '_blank');
  }


  goDetail( idx: number) {

    if ( this.securityService.isAuthenticated() ) {
      this.router.navigate([`/private/event/${ this.fileList[idx].indice}`]);

    } else {
      this.router.navigate([`/event/${ this.fileList[idx].indice}`]);

    }

  }


}

import { Component, OnInit } from '@angular/core';

import faq from '../../../assets/faq.json';



@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  // tslint:disable-next-line:variable-name
  _faq = faq;

  constructor() { }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';

import { environment } from '../../../environments/environment';

// -----------------------------------------------------------------------------
//  Services
// -----------------------------------------------------------------------------
import { SecurityService } from '../platform/security.service';

@Injectable({
  providedIn: 'root'
})
export class CreditService {
// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( private http: HttpClient, private securityService: SecurityService ) {
    console.log('Credit service loaded');
  }
// -----------------------------------------------------------------------------
//  Create a user
// -----------------------------------------------------------------------------
  save( data: any ) {

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.securityService.dataSession.authorization );

    if ( data._id === '' ) {

      return this.http.post( `${ environment.endpoint.creditRequest }/request`,  data , { headers } )
      .pipe( retry(5), map( (response: any) => {
            return response.businessResponse;
      }), catchError((err) => {
            switch ( err.status ) {
              case 401:
                this.securityService.singOut();
                break;
              default:
              break;
            }
            return throwError(err.status);
      }));

    } else {

      return this.http.patch( `${ environment.endpoint.creditRequest }/request/${ data._id }`,  data , { headers } )
      .pipe( retry(5), map( (response: any) => {
            return response.businessResponse;
      }), catchError((err) => {
            switch ( err.status ) {
              case 401:
                this.securityService.singOut();
                break;
              default:
              break;
            }
            return throwError(err.status);
      }));
    }

  }
// -----------------------------------------------------------------------------
//  Create a user
// -----------------------------------------------------------------------------
evaluation( id: any ) {

  let headers = new HttpHeaders();
  headers = headers.set('Authorization', this.securityService.dataSession.authorization );

  return this.http.patch( `${ environment.endpoint.creditRequest }/request/${ id }/evaluation`, {}, { headers } )
  .pipe( retry(5), map( (response: any) => {
        return response.businessResponse;
      }), catchError((err) => {
        switch ( err.status ) {
          case 401:
            this.securityService.singOut();
            break;
          default:
          break;
        }
        return throwError(err.status);
  }));
}
// -----------------------------------------------------------------------------
//  Create a user
// -----------------------------------------------------------------------------
  evaluationTest( id: any ) {

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.securityService.dataSession.authorization );

    return this.http.get( `${ environment.endpoint.creditRequest }/request/${ id }/evaluation`, { headers } )
    .pipe( retry(5), map( (response: any) => {
          return response.businessResponse;
        }), catchError((err) => {
          switch ( err.status ) {
            case 401:
              this.securityService.singOut();
              break;
            default:
            break;
          }
          return throwError(err.status);
    }));
  }
// -----------------------------------------------------------------------------
//  Get branch list
// -----------------------------------------------------------------------------
  getByID( id: string ) {

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.securityService.dataSession.authorization );

    return this.http.get( `${ environment.endpoint.creditRequest }/request/${ id }` , { headers } )
      .pipe( retry(5), map( (response: any) => {
            return response.businessResponse;
      }), catchError((err) => {
            switch ( err.status ) {
              case 401:
                this.securityService.singOut();
                break;
              default:
              break;
            }
            return throwError(err.status);
      }));
  }

}

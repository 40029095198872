import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SecurityService } from '../platform/security.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {


  constructor( private securityService: SecurityService,
               private router: Router ) {
    console.log('Login guard activated');
  }



  canActivate() {

    if ( this.securityService.isAuthenticated() ) {

      console.log('Route enable');
      return true;

    } else {

      console.log('Route blocked');
      this.router.navigate(['/']);
      return false;

    }

  }


}

import { Component, OnInit } from '@angular/core';
import { CatalogService } from '../../services/services.index';
import { environment } from 'src/environments/environment.js';


@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit {
  existFile = false;

  searchigFiles = true;
  fileList = [];



  awsS3Url = environment.endpoint.awsS3 + 'files/';

  constructor( private catalogService: CatalogService) { }

  ngOnInit() {



    this.searchigFiles = true;
    this.catalogService.getInformationFiles().subscribe( resp => {


      resp.Contents.shift();

      resp.Contents.forEach( item => {

        const c = item.Key.split('/');
        const ext = c[1].split('.');

        this.fileList.push({
          file: ext [0],
          ext: ext [ ext.length - 1 ]
         } );

      });

      this.searchigFiles = false;

    });
  }


  showDocument(urlResource) {
    window.open(urlResource, '_blank');
  }

}

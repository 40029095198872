import { Component, OnInit } from '@angular/core';
import { SecurityService } from '../services/platform/security.service';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {

  constructor( public securityService: SecurityService) { }

  ngOnInit() {
  }


  closeSession() {

    this.securityService.singOut();

  }

}

import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from '../services/guards/login.guard';

import { PublicComponent } from './public.component';
// import { SettingsComponent } from './general/settings/settings.component';
// import { PlatformComponent } from './platform/platform.component';
// import { AdministrationComponent } from './administration/administration.component';
// import { BusinessBlocksComponent } from './business-blocks/business-blocks.component';

import { Page404Component } from '../shared/error/page-404/page-404.component';
import { CreditRequestComponent } from './credit-request/credit-request.component';
import { FaqComponent } from '../landing-page/faq/faq.component';
import { InformationComponent } from '../landing-page/information/information.component';
import { EventsComponent } from '../landing-page/events/events.component';
import { EventDetailComponent } from '../landing-page/events/event-detail/events-detail.component';
import { HomeComponent } from '../landing-page/home/home.component';

const publicRoutes:
Routes = [
    {
        path: 'private',
        component: PublicComponent,
  //    canActivate: [ LoginGuard ],
        children: [
            {
                path: '',
                component: CreditRequestComponent,
                canActivate: [ LoginGuard ],
                loadChildren: './credit-request/credit-request.module#CreditRequestModule'
            },

            {
                path: 'home',
                component: HomeComponent,
            },

            {
                path: 'faq',
                component: FaqComponent,
                data: {
                    title: 'Pagina no encontrada',
                    route: []
                }
            },

            {
                path: 'information',
                component: InformationComponent,
                data: {
                    title: 'Pagina no encontrada',
                    route: []
                }
            },
            {
                path: 'events',
                component: EventsComponent,
                data: {
                    title: 'Eventos',
                    route: []
                }
            },
            {
                path: 'event/:id',
                component: EventDetailComponent,
                data: {
                    title: 'Eventos',
                    route: []
                }
            },
            {
                path: '**',
                component: HomeComponent,
                canActivate: [ LoginGuard ],
                data: {
                    title: 'Pagina no encontrada',
                    route: []
                }
            }

            // {
            //     path: 'creditRequest',
            //     component: CreditRequestComponent,
            //     data: {
            //         title: 'Perfil de usuario',
            //         route: []
            //     }
            // },

            // }

            // {
            //     path: 'settings',
            //     component: SettingsComponent,
            //     data: { title: 'Ajustes' }
            // },
            // {
            //     path: '',
            //     component: PlatformComponent,
            //     canActivate: [ LoginGuard ],
            //     loadChildren: './platform/platform.module#PlatformModule'
            // },
            // {
            //     path: '',
            //     component: AdministrationComponent,
            //     canActivate: [ LoginGuard ],
            //     loadChildren: './administration/administration.module#AdministrationModule'
            // },

        ]
    }
];

export const PUBLIC_ROUTES = RouterModule.forChild( publicRoutes );


import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LandingPageComponent } from './landing-page/landing-page.component';
// import { HomeComponent } from './landing-page/home/home.component';
// import { FaqComponent } from './landing-page/faq/faq.component';


const routes: Routes = [

  // {
  //   path: '',
  //   component: LandingPageComponent,
  //   data: {
  //     title: 'ProSostenible',
  //     route: []
  //   }
  // },

  // {
  //   path: 'signin/:action/:id',
  //   component: HomeComponent,
  //   data: {
  //     title: 'ProSostenible',
  //     route: []
  //   }
  // },




// {
//   path: 'signin/:action/:token',
//   component: SignInComponent,
//   data: {
//     title: 'Cambiar contraseña',
//     route: []
//   }
// },



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


export const APP_ROUTES = RouterModule.forRoot( routes, { useHash: true } );








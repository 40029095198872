import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import {Observable} from 'rxjs';

import { environment } from '../../../environments/environment';


// import { Observable } from 'rxjs/Observable';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
// // tslint:disable-next-line:import-blacklist
// import 'rxjs/Rx';
// -----------------------------------------------------------------------------
//  Services
// -----------------------------------------------------------------------------
import { SecurityService } from './security.service';
// -----------------------------------------------------------------------------
//  Models
// -----------------------------------------------------------------------------
import { User } from '../../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public user: User;

// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( private http: HttpClient, private securityService: SecurityService ) {
    console.log('User service loaded');
  }
// -----------------------------------------------------------------------------
//  Create a user
// -----------------------------------------------------------------------------
createCustomer( user: User, password: string  ) {

      return this.http.post( `${ environment.endpoint.security }/user/customer`, { user, password } ).pipe( map( (response: any) => {

        return response.headerResponse;

      }));

  }
// -----------------------------------------------------------------------------
//  Create a user
// -----------------------------------------------------------------------------
confirm( token: string, password: string ) {

  // return this.http.post( `${ END_POINT }/security/user/activate/${ token }`, { password } )
  //   .map( (response: any) => {

  //     console.log(response);
  //     return response.businessResponse;

  //   }).catch( (err: any) => Observable.throw(err.status) );

}

// -----------------------------------------------------------------------------
//  Update user
// -----------------------------------------------------------------------------
  update( user: User ) {

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.securityService.dataSession.authorization );

    console.log(`${ environment.endpoint.security }/user/${ user.id }`);
    console.log(user);


    return this.http.patch( `${ environment.endpoint.security }/user/${ user.id }`, user, { headers } ).pipe( map( (response: any) => {

      alert('user updated');

      return response.businessResponse;

    }));



    // return this.http.patch( `${ END_POINT }/security/user/${ user._id }`, user, { headers } )
    //   .map( (response: any) => {
    //     if ( user._id === this.user._id ) {
    //       this.user = new User(
    //         response.businessResponse.user.name,
    //         response.businessResponse.user.firstLastName,
    //         response.businessResponse.user.secondLastName,
    //         response.businessResponse.user.user,
    //         response.businessResponse.user.role,
    //         response.businessResponse.user.brithday,
    //         response.businessResponse.user.gene,
    //         response.businessResponse.user.image,
    //         response.businessResponse.user._id,
    //         response.businessResponse.user.organization,
    //         response.businessResponse.user.creationDate,
    //         response.businessResponse.user.updateDate
    //       );
    //       localStorage.setItem('user', JSON.stringify(this.user));
    //     }
    //     return response.businessResponse;
    //   }).catch( error => {
    //     if (error.status === 401) {
    //       this.singOut();
    //     }
    //     return Observable.throw(error.status);
    //   } );

  }
// -----------------------------------------------------------------------------
//  Update business
// -----------------------------------------------------------------------------
updateField( user: any ) {

  // let headers = new HttpHeaders();
  // headers = headers.set('Authorization', this.authorization );

  // return this.http.patch( `${ END_POINT }/security/user/${ user._id }`, user, { headers } )
  //   .map( (response: any) => {

  //     console.log(user);

  //     if ( 'image' in user) {
  //       this.user.image = user.image;
  //       localStorage.setItem('user', JSON.stringify(this.user));
  //     }

  //     return response.businessResponse;

  //   }).catch( error => {
  //     if (error.status === 401) {
  //         this.singOut();
  //     }
  //     return Observable.throw(error.status);
  //   });

}
// -----------------------------------------------------------------------------
//  Get users list
// -----------------------------------------------------------------------------
  get() {

    // let headers = new HttpHeaders();
    // headers = headers.set('Authorization', this.authorization );

    // return this.http.get( `${ END_POINT }/security/user`, { headers } )
    //   .map( (response: any) => {

    //     return response.businessResponse;

    //   }).catch( error => {
    //     if (error.status === 401) {
    //       this.singOut();
    //     }
    //     return Observable.throw(error.status);
    //   } );
  }
// -----------------------------------------------------------------------------
//  Get users by id
// -----------------------------------------------------------------------------
getById( id: string ) {

  // let headers = new HttpHeaders();
  // headers = headers.set('Authorization', this.authorization );

  // return this.http.get( `${ END_POINT }/security/user/${ id }`, { headers } )
  //   .map( (response: any) => {

  //     return response.businessResponse;

  //   }).catch( error => {
  //     if (error.status === 401) {
  //       this.singOut();
  //     }
  //     return Observable.throw(error.status);
  //   } );
}





}

import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { FaqComponent } from './faq/faq.component';
import { LandingPageComponent } from './landing-page.component';
import { InformationComponent } from './information/information.component';
import { EventsComponent } from './events/events.component';
import { EventDetailComponent } from './events/event-detail/events-detail.component';

import { Page404Component } from '../shared/error/page-404/page-404.component';
import { CreditRequestComponent } from '../public/credit-request/credit-request.component';
import { TermsComponent } from './terms/terms.component';

const publicRoutes:
Routes = [
    {
        path: 'signin/:action/:id',
        component: LandingPageComponent,
        data: {
          title: 'ProSostenible',
          route: []
        }
      },
    {
        path: '', component: LandingPageComponent,
        children: [
            {
                path: '',
                component: HomeComponent,
            },
            {
                path: 'faq',
                component: FaqComponent,
                data: {
                    title: 'Pagina no encontrada',
                    route: []
                }
            },
            {
                path: 'information',
                component: InformationComponent,
                data: {
                    title: 'Pagina no encontrada',
                    route: []
                }
            },
            {
                path: 'events',
                component: EventsComponent,
                data: {
                    title: 'Eventos',
                    route: []
                }
            },
            {
                path: 'event/:id',
                component: EventDetailComponent,
                data: {
                    title: 'Eventos',
                    route: []
                }
            },
            {
                path: 'privacy_policy',
                component: TermsComponent
            },
            {
                path: '**',
                component: HomeComponent,
                data: {
                    title: 'Pagina no encontrada',
                    route: []
                }
            }

        ]
    }
];

export const LANDINGPAGE_ROUTES = RouterModule.forChild( publicRoutes );


import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';

import { environment } from 'src/environments/environment.js';
import config from '../../config/config.json';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  version = config.version;
  environmentName = environment.name;


// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( public router: Router, private dialog: MatDialog ) {
  }
// -----------------------------------------------------------------------------
//  Object init
// -----------------------------------------------------------------------------
  ngOnInit() {

  }

  // -----------------------------------------------------------------------------
  //  Terms
  // -----------------------------------------------------------------------------
  goTerms() {
    window.open(`${ environment.endpoint.publicWeb }/privacy_policy`, '_blank');
  }
  // -----------------------------------------------------------------------------
  //  Terms
  // -----------------------------------------------------------------------------
  goFaq() {
    this.router.navigate([`/faq`]);
  }
  // -----------------------------------------------------------------------------
  //  Terms
  // -----------------------------------------------------------------------------
  goInfo() {
    this.router.navigate([`/information`]);
  }
}

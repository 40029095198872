import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LANDINGPAGE_ROUTES } from './landing-page.routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
/* MODULES */
import { SharedModule } from '../shared/shared.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSidenavModule} from '@angular/material';
import {MatToolbarModule} from '@angular/material';
import {MatIconModule} from '@angular/material';
import {MatListModule} from '@angular/material';
import {MatExpansionModule} from '@angular/material';
import {MatButtonModule} from '@angular/material';
import {MatMenuModule} from '@angular/material';
import {MatCardModule} from '@angular/material';
import {MatCheckboxModule} from '@angular/material';
import {MatRadioModule} from '@angular/material';
import {MatStepperModule } from '@angular/material';
import {MatTooltipModule} from '@angular/material';
import {MatOptionModule, MatSelectModule} from '@angular/material';
import {MatButtonToggleModule} from '@angular/material';
import {MatDatepickerModule, MatInputModule, MatNativeDateModule} from '@angular/material';
import {MatGridListModule} from '@angular/material';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import { HomeComponent } from './home/home.component';
import { FaqComponent } from './faq/faq.component';
import { LandingPageComponent } from './landing-page.component';
import { InformationComponent } from './information/information.component';
import { EventsComponent } from './events/events.component';
import { EventDetailComponent } from './events/event-detail/events-detail.component';


@NgModule({
  imports: [
    LANDINGPAGE_ROUTES,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule, MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatOptionModule, MatSelectModule, MatCheckboxModule,
    MatRadioModule,
    MatStepperModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatDatepickerModule, MatInputModule, MatNativeDateModule,
    MatGridListModule
  ],
  declarations: [
    HomeComponent,
    FaqComponent,
    LandingPageComponent,
    InformationComponent,
    EventsComponent,
    EventDetailComponent
  ]
})

export class LandingPageModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PUBLIC_ROUTES } from './public.routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';


import { CreditRequestComponent } from './credit-request/credit-request.component';



/* MODULES */
import { SharedModule } from '../shared/shared.module';
// import { PipesModule } from '../pipes/pipes.module';

/* SECTIONS */
// import { ProfileComponent } from './general/profile/profile.component';
// import { SettingsComponent } from './general/settings/settings.component';

// import { PlatformComponent } from './platform/platform.component';
// import { AdministrationComponent } from './administration/administration.component';
// import { BusinessBlocksComponent } from './business-blocks/business-blocks.component';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatSidenavModule} from '@angular/material';
import {MatToolbarModule} from '@angular/material';
import {MatIconModule} from '@angular/material';
import {MatListModule} from '@angular/material';
import {MatExpansionModule} from '@angular/material';
import {MatButtonModule} from '@angular/material';
import {MatMenuModule} from '@angular/material';
import {MatCardModule} from '@angular/material';
import {MatCheckboxModule} from '@angular/material';
import {MatRadioModule} from '@angular/material';
import {MatStepperModule } from '@angular/material';
import {MatTooltipModule} from '@angular/material';
import {MatOptionModule, MatSelectModule} from '@angular/material';

import {MatButtonToggleModule} from '@angular/material';
import {MatDatepickerModule, MatInputModule, MatNativeDateModule} from '@angular/material';
import {MatGridListModule} from '@angular/material';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import { PublicComponent } from './public.component';



@NgModule({
  imports: [
    PUBLIC_ROUTES,
    CommonModule,

    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    // PipesModule


    FlexLayoutModule,


    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule, MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatOptionModule, MatSelectModule, MatCheckboxModule,
    MatRadioModule,
    MatStepperModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatAutocompleteModule,

    MatDatepickerModule, MatInputModule, MatNativeDateModule,
    MatGridListModule

  ],
  declarations: [
    PublicComponent,

    CreditRequestComponent,

    // ProfileComponent,

    // AdministrationComponent,
    // PlatformComponent,
    // BusinessBlocksComponent,

    // SettingsComponent


  // UploadComponent


  ]
})

export class PublicModule { }

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import {Observable} from 'rxjs';
import { throwError } from 'rxjs';

import { environment } from '../../../environments/environment';

// import { Observable } from 'rxjs/Observable';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
// // tslint:disable-next-line:import-blacklist
// import 'rxjs/Rx';

// -----------------------------------------------------------------------------
//  Models
// -----------------------------------------------------------------------------
import { User } from '../../models/user.model';
// import { BusinessModel } from '../../models/business.model';


@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  authenticated = false;
  public dataSession: any = {
    authorization: '',
    user: User,
    policies: [],
    menu: []
  };

  authorization = '';
  menu: any;
  policies: any[] = [];
  public user: User;

// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( private http: HttpClient, private router: Router ) {

    console.log('Security service loaded');
    this.initSession();

  }
// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  initSession() {
    console.log('validating session');
    const dataSessionString = localStorage.getItem('dataSession');
    if ( dataSessionString !== null ) {

      this.authenticated = true;
      const dataSessionParse = JSON.parse( dataSessionString );

      this.dataSession.authorization = dataSessionParse.authorization;
      this.dataSession.user = new User(
        dataSessionParse.user.name,
        dataSessionParse.user.firstLastName,
        dataSessionParse.user.secondLastName,
        dataSessionParse.user.email,
        dataSessionParse.user.role,
        dataSessionParse.user.brithday,
        dataSessionParse.user.gene,
        dataSessionParse.user.image,
        dataSessionParse.user.id,
        dataSessionParse.user.organization,
        dataSessionParse.user.creationDate,
        dataSessionParse.user.updateDate
      );

      this.dataSession.policies = dataSessionParse.policies;
      this.dataSession.menu = this.createMenu();

    } else {
    //  this.singOut();
    }
  }
// -----------------------------------------------------------------------------
//  Create a user
// -----------------------------------------------------------------------------
  // create( type: string, user: User ) {

  //   let headers = new HttpHeaders();

  //   if (type === 'COMMON') {
  //     headers = headers.set('Authorization', this.authorization );
  //   } else {
  //     headers = headers.set('Authorization', 'no necesary' );
  //   }

  //   user.role = type;

  //   return this.http.post( `${ END_POINT }/security/user`, user, { headers } )
  //     .map( (response: any) => {

  //       console.log(response);
  //       return response.businessResponse;

  //     }).catch( (err: any) => Observable.throw(err.status) );

  // }
// -----------------------------------------------------------------------------
//  Create a user
// -----------------------------------------------------------------------------
// confirm( token: string, password: string ) {

//   return this.http.post( `${ END_POINT }/security/user/activate/${ token }`, { password } )
//     .map( (response: any) => {

//       console.log(response);
//       return response.businessResponse;

//     }).catch( (err: any) => Observable.throw(err.status) );

// }
// -----------------------------------------------------------------------------
//  Start session
// -----------------------------------------------------------------------------
  signIn( user: string, password: string, rememberme: boolean ) {

    console.log('starting session');

    if (rememberme) {
      localStorage.setItem('email', user);
    } else {
      localStorage.removeItem('email');
    }

    return this.http.post( `${ environment.endpoint.security }/login`, { user, password } ).pipe( map( (response: any) => {


      if (response.businessResponse.user.role === 'CUSTOMER' ) {


        this.authenticated = true;
        this.dataSession.authorization = response.businessResponse.authorization;
        this.dataSession.user = new User(
          response.businessResponse.user.name,
          response.businessResponse.user.firstLastName,
          response.businessResponse.user.secondLastName,
          response.businessResponse.user.email,
          response.businessResponse.user.role,
          response.businessResponse.user.brithday,
          response.businessResponse.user.gene,
          response.businessResponse.user.image,
          response.businessResponse.user._id,
          response.businessResponse.user.organization,
          response.businessResponse.user.creationDate,
          response.businessResponse.user.updateDate
        );

        console.log(this.dataSession.user );

        this.dataSession.policies = response.businessResponse.policies;
        this.dataSession.menu = this.createMenu();
        localStorage.setItem('dataSession', JSON.stringify(this.dataSession));
        return response.businessResponse;


      } else {

        this.singOut();
        return null;

      }



    }));
  }
// -----------------------------------------------------------------------------
//  Close session
// -----------------------------------------------------------------------------
  singOut() {
    console.log('closing session');

    this.dataSession.authorization = '';
    this.dataSession.user = null;
    this.dataSession.policies = [];
    this.dataSession.menu = [];
    this.authenticated = false;
    localStorage.removeItem('dataSession');

    this.router.navigate(['/']);
  }
// -----------------------------------------------------------------------------
//  Update user
// -----------------------------------------------------------------------------
  // update( user: User ) {

  //   let headers = new HttpHeaders();
  //   headers = headers.set('Authorization', this.authorization );

  //   return this.http.patch( `${ END_POINT }/security/user/${ user._id }`, user, { headers } )
  //     .map( (response: any) => {

  //       if ( user._id === this.user._id ) {

  //         this.user = new User(
  //           response.businessResponse.user.name,
  //           response.businessResponse.user.firstLastName,
  //           response.businessResponse.user.secondLastName,
  //           response.businessResponse.user.user,
  //           response.businessResponse.user.role,
  //           response.businessResponse.user.brithday,
  //           response.businessResponse.user.gene,
  //           response.businessResponse.user.image,
  //           response.businessResponse.user._id,
  //           response.businessResponse.user.organization,
  //           response.businessResponse.user.creationDate,
  //           response.businessResponse.user.updateDate
  //         );

  //         localStorage.setItem('user', JSON.stringify(this.user));

  //       }

  //       return response.businessResponse;

  //     }).catch( error => {
  //       if (error.status === 401) {
  //         this.singOut();
  //       }
  //       return Observable.throw(error.status);
  //     } );
  // }
  // -----------------------------------------------------------------------------
//  Update business
// -----------------------------------------------------------------------------
// updateField( user: any ) {

//   let headers = new HttpHeaders();
//   headers = headers.set('Authorization', this.authorization );

//   return this.http.patch( `${ END_POINT }/security/user/${ user._id }`, user, { headers } )
//     .map( (response: any) => {

//       console.log(user);

//       if ( 'image' in user) {
//         this.user.image = user.image;
//         localStorage.setItem('user', JSON.stringify(this.user));
//       }

//       return response.businessResponse;

//     }).catch( error => {
//       if (error.status === 401) {
//           this.singOut();
//       }
//       return Observable.throw(error.status);
//     });

// }
// -----------------------------------------------------------------------------
//  Return status of session
// -----------------------------------------------------------------------------
  isAuthenticated() {

    return this.authenticated;

  }
// -----------------------------------------------------------------------------
//  Get users list
// -----------------------------------------------------------------------------
  // get() {

  //   let headers = new HttpHeaders();
  //   headers = headers.set('Authorization', this.authorization );

  //   return this.http.get( `${ END_POINT }/security/user`, { headers } )
  //     .map( (response: any) => {

  //       return response.businessResponse;

  //     }).catch( error => {
  //       if (error.status === 401) {
  //         this.singOut();
  //       }
  //       return Observable.throw(error.status);
  //     } );
  // }
// -----------------------------------------------------------------------------
//  Get users by id
// -----------------------------------------------------------------------------
// getById( id: string ) {

//   let headers = new HttpHeaders();
//   headers = headers.set('Authorization', this.authorization );

//   return this.http.get( `${ END_POINT }/security/user/${ id }`, { headers } )
//     .map( (response: any) => {

//       return response.businessResponse;

//     }).catch( error => {
//       if (error.status === 401) {
//         this.singOut();
//       }
//       return Observable.throw(error.status);
//     } );
// }

// -----------------------------------------------------------------------------
//  Recover password
// -----------------------------------------------------------------------------
recoverPassword( user: User ) {

  return this.http.post( `${ environment.endpoint.security }/recoverpassword`, { user } ).pipe( map( (response: any) => {

    return response.businessResponse;

  }), catchError((err) => {
    switch ( err.status ) {
      case 401:
        break;
      default:
      break;
    }
    return throwError(err.status);
  }));
}
// -----------------------------------------------------------------------------
//  Change password
// -----------------------------------------------------------------------------
changePassword( token: string, password: string ) {

  return this.http.patch( `${ environment.endpoint.security }/changepassword/${ token }`, { password } ).pipe( map( (response: any) => {

    return response.businessResponse;

  }), catchError((err) => {
    switch ( err.status ) {
      case 401:
        break;
      default:
      break;
    }
    return throwError(err.status);
  }));
}


// -----------------------------------------------------------------------------
//  Return data user
// -----------------------------------------------------------------------------
  getUserAuthenticated() {
    return this.dataSession.user;
  }

  setUserAuthenticated( user: User) {
    this.dataSession.user = user;
    localStorage.setItem('dataSession', JSON.stringify(this.dataSession));
  }


createMenu( ) {

  const menu = {
    platform: [],
    administration: [],
    modules: []
  };

  if (this.dataSession.user.role === 'ROOT') {

      menu.platform = [{
          title: 'Catálogos',
          icon: 'mdi mdi-bulletin-board',
          url: '/administration/business',
          submenu: [
              { title: 'Tipos de negocio', url: '/platform/catalogs/businessType' },
              { title: 'Servicios extra', url: '/platform/catalogs/extraServices' },
              { title: 'Medios de contacto', url: '/platform/catalogs/contactType' }
          ]
      }];

  }






  if (this.dataSession.user.role === 'ADMINISTRATOR') {

      menu.administration = [
        {
          title: 'Negocios',
          icon: 'event_available',
          url: '/administration/business',
          submenu: [
          ]
      },


      {
        title: 'Módulos',
        icon: 'event_available',
        url: '/administration/yectia/modules/market',
        submenu: [
          {
            title: 'Negocios',
            icon: 'event_available',
            url: '/administration/business'
          },
          {
            title: 'Productos y servicios',
            icon: 'event_available',
            url: '/administration/business/products'
          },
          {
            title: 'Modulos',
            icon: 'event_available',
            url: '/administration/yectia/modules/market'
          }
        ]
      }


    ];


  }



  if (this.dataSession.user.role === 'ADMINISTRATOR' || this.dataSession.user.role === 'COMMON') {

      menu.modules = [{
              title: 'Dashboard',
              icon: 'event_available',
              url: '/module/dashboard',
              submenu: [
                  {
                    title: 'Principal 1',
                    icon: 'event_available',
                    url: '/module/dashboard' }
              ]
          }
          // ,
          // {
          //     title: 'Inventario',
          //     icon: 'mdi mdi-view-list',
          //     submenu: [
          //         { title: 'Productos', url: '/inventory' }
          //     ]
          // },
          // {
          //     title: 'Más modulos',
          //     icon: 'mdi mdi-view-list',
          //     submenu: [
          //         { title: 'Productos', url: '/inventory' }
          //     ]
          // }
      ];

  }

  return menu;


}


}

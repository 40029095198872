
export const environment = {
  production: false,

  name: 'Test',
  endpoint: {
    publicWeb: 'https://test.prosostenible.org',

    creditRequest: 'https://credit.api.test.prosostenible.org',
    resource: 'https://resource.api.test.prosostenible.org',
    security: 'https://security.api.test.prosostenible.org',

    awsS3: 'https://paps-system.s3.us-east-2.amazonaws.com/'
  }

};


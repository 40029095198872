import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginGuard } from './guards/login.guard';
// import { HttpClientModule } from '@angular/common/http';

import {
  SecurityService,
  UserService
//   SettingsService,
//   SharedService,
//   SidebarService,
//   UserService,
//   BusinessTypeService,
//   BusinessService,
//   ProductService,
//   CatalogService
} from './services.index';


@NgModule({
  imports: [
    CommonModule,
  //  HttpClientModule
  ],
  providers: [
    SecurityService,
    LoginGuard,
    // SettingsService,
    // SharedService,
    // SidebarService,
     UserService,

    // BusinessTypeService,
    // BusinessService,
    // ProductService,
    // CatalogService
  ],
  declarations: []
})
export class ServicesModule { }

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';

import { environment } from '../../../environments/environment';

// -----------------------------------------------------------------------------
//  Services
// -----------------------------------------------------------------------------
import { SecurityService } from './security.service';
// -----------------------------------------------------------------------------
//  Models
// -----------------------------------------------------------------------------
@Injectable({
  providedIn: 'root'
})
export class CatalogService {
// -----------------------------------------------------------------------------
//  Object constructor
// -----------------------------------------------------------------------------
  constructor( private http: HttpClient, private securityService: SecurityService ) {
    console.log('Catalog service loaded');
  }
// -----------------------------------------------------------------------------
//  Get branch list
// -----------------------------------------------------------------------------
  get( catalog: string, sort: string = ''  ) {

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.securityService.dataSession.authorization );

    let sortString = '';
    if ( sort !== '' ) {
      sortString =  `?sort=${ sort }`;
    }

    return this.http.get( `${ environment.endpoint.creditRequest }/catalogs/${ catalog }${ sortString }`, { headers } )
    .pipe( retry(5), map( (response: any) => {
      return response.businessResponse.items;
    }), catchError((err) => {
      switch ( err.status ) {
        case 401:
          this.securityService.singOut();
          break;
        default:
        break;
      }
      return throwError(err.status);
    }));

}




getInformationFiles( ) {

  let headers = new HttpHeaders();
  headers = headers.set('Authorization', this.securityService.dataSession.authorization );


  return this.http.get( `${ environment.endpoint.resource }/information/files`, { headers } )
  .pipe( retry(5), map( (response: any) => {
    return response.businessResponse;
  }), catchError((err) => {
    switch ( err.status ) {
      case 401:
        this.securityService.singOut();
        break;
      default:
      break;
    }
    return throwError(err.status);
  }));

}



getEvents( ) {

  let headers = new HttpHeaders();
  headers = headers.set('Authorization', this.securityService.dataSession.authorization );


  return this.http.get( `${ environment.endpoint.resource }/information/events`, { headers } )
  .pipe( retry(5), map( (response: any) => {
    return response.businessResponse;
  }), catchError((err) => {
    switch ( err.status ) {
      case 401:
        this.securityService.singOut();
        break;
      default:
      break;
    }
    return throwError(err.status);
  }));

}


getEvent( id: string ) {

  let headers = new HttpHeaders();
  headers = headers.set('Authorization', this.securityService.dataSession.authorization );


  return this.http.get( `${ environment.endpoint.resource }/information/event/${ id }`, { headers } )
  .pipe( retry(5), map( (response: any) => {
    return response.businessResponse;
  }), catchError((err) => {
    switch ( err.status ) {
      case 401:
        this.securityService.singOut();
        break;
      default:
      break;
    }
    return throwError(err.status);
  }));

}



}
